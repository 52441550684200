<template>
    <div>
        <h2>{{ $t("mouvement.lieu_mouvement") }}</h2>
        <HorseIntraLocation ref="intra_location" :horse_id="horse_id" />

        <div v-show="main_vue">
            <h2>{{ $t("mouvement.historique_mouvement" )}}</h2>
            <div class="box">
                <CustomTable
					ref="mouv_table"
                    :id_table="id_table"
                    primaryKey="mouvement_id"
                    selectMode="single"
					:transformer="['MouvementTransformer', 'withHorseAndType']"
                    :busy.sync="table_busy"
                    :display_action_button="!horseFicheReadOnly"
                    :checkboxes="!horseFicheReadOnly"
					:date-filter="dateFilter"
					:base-filters="filters"
					:persistDateFilter="true"
                />
            </div>
        </div>
    </div>
</template>


<script type="text/javascript">
    import Mouvements from "@/mixins/Mouvements.js"
    import Navigation from "@/mixins/Navigation.js"

	export default {
		name: "historiqueMouvements",
		mixins: [
            Mouvements,
            Navigation
        ],
        props: [
            'horse_id',
        ],
        data () {
			return {
                id_table: "mouvements_horse",
                mouvements: null,
                main_vue: true,
                events_tab: {
                    'HistoriqueMouvement::editResidence': this.editResidence,
                    'HistoriqueMouvement::updateMouvements': this.reloadMouvementsListe,
                    'TableAction::DeleteMouvements': this.handleDeleteMouvements
                },

                /* Configuration du tableau : */
                table_busy: true,
				dateFilter: {
					column: 'mouvement_date',
				},
			}
		},
        created() {
            if(this.getConfig('startDate' + this.id_table)) {
                this.dateFilter.start = new Date(this.getConfig('startDate' + this.id_table))
            }

            if(this.getConfig('endDate' + this.id_table)) {
                this.dateFilter.end = new Date(this.getConfig('endDate' + this.id_table))
            }
        },
		methods: {
            editResidence(status) {
                this.main_vue = !status
			},
			
			reloadMouvementsListe() {
				this.$refs.mouv_table.refreshTable()
				this.$refs.intra_location.refresh()
			},

			async handleDeleteMouvements(mouvements) {
				await this.applyDeleteMouvements(mouvements)
				this.reloadMouvementsListe()
			}
        },
        computed: {
            hasTiersAccess() {
                return this.$store.state.userAccess.hasFacturationAccess
            },
            horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
			},
			filters() {
				return {
					is_this_horse: {
						column: 'mouvement_horse',
						operator: 'isEqualTo',
						value: this.$sync.replaceWithReplicated('horse', this.horse_id)
					},
                    is_mouvement_interne: {
                        column: 'mouvement_type',
                        operator: 'isEqualTo',
                        value: 5
                    }
				}
			}
        },
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			HorseResidence: () => import('@/components/ShutterScreens/Horse/HorseResidence'),
            HorseIntraLocation: () => import('@/components/Horse/HorseIntraLocation'),
			HorseLieuStationnement: () => import('@/components/Horse/HorseLieuStationnement')
		}
	}
</script>
